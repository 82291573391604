import styled, { css } from 'styled-components'
import { clearFix } from 'polished'

const Row = styled.div`
  ${clearFix()} width: 100%;
  margin: 0 -15px;
`

const Column = styled.div`
  box-sizing: border-box;
  float: left;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  ${props =>
    props.off &&
    css`
      margin-left: ${props => (props.off / 12) * 100 || 0}%;
    `} @media (min-width: 768px) {
    width: ${props => (props.s / 12) * 100 || 12}%;
  }
  @media (min-width: 990px) {
    width: ${props => (props.m / 12) * 100 || 12}%;
  }
  @media (min-width: 1200px) {
    width: ${props => (props.l / 12) * 100 || 12}%;
    ${props =>
      props.lo &&
      css`
        margin-left: ${props => (props.lo / 12) * 100 || 0}%;
      `};
  }
  @media (min-width: 2000px) {
    width: ${props => (props.xl / 12) * 100 || 12}%;
  }
`
export { Row, Column }
