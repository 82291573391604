import styled from 'styled-components'

const Container = styled.div`
  background-color: #1f1f1f;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 30px;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  box-sizing: border-box;
`
export default Container
